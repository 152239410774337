<template>
  <div>
    <div class="p-t-10">
      <div
        v-for="tag in tagList"
        :key="tag.key"
        class="p-l-20 p-r-20 m-b-2"
        @click="handleChangeTag(tag.key)"
        :class="
          currentTag === tag.key
            ? 'workplatform-radio-button-active'
            : 'workplatform-radio-button'
        "
      >
        {{ tag.name }}
      </div>
    </div>
    <div v-if="contractSetting && contractId">
      <ContractFileSetting ></ContractFileSetting>
    </div>
    <div v-else-if="statementSetting && contractId">
      <StatementFileSettting ></StatementFileSettting>
    </div>
    <div v-else>
      <div v-if="allowEdit">
        <Divider dashed><span class="divider-text">创建{{ tagName }}</span></Divider>
        <component :is="createComponent" @on-create="handleCreate"></component>
      </div>
      <div v-else>
        <div class="default-content">
          <span class="workplatform-title">{{ tagName }}详细信息请在右侧查看</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { sysMixins } from '@/assets/mixins/sys'
import ContractFileSetting from './components/ContractFileSettting'
import StatementFileSettting from './components/StatementFileSettting'
export default {
  mixins: [sysMixins],
  components: {
    CreateContract: () => import('./components/CreateContract'),
    ContractFileSetting,
    StatementFileSettting,
    // CreateAgreement: () => import('./components/CreateAgreement'),
    CreateAgreement: () => import('./components/CreateAgreementV2'),
    CreateAgreementChange: () => import('./components/CreateAgreementChange'),

    CreateContractChange: () => import('./components/CreateContractChange'),
    CreateStatementChange: () => import('./components/CreateStatementChange'),
    CreateInvoice: () => import('./components/CreateInvoice'),
    CreateStatementInvoice: () => import('./components/CreateStatementInvoice'),
    CreateStatement: () => import('./components/CreateStatement')
  },
  data () {
    return {
      allowEdit: false,

      createComponent: '',
      currentTag: 1,
      tagName: '',
      tagList: [
        // { key: 1, name: '合同' },
        // { key: 2, name: '合同变更' },
        // { key: 3, name: '合同开票' },
        // { key: 4, name: '确认单' },
        // { key: 5, name: '结算单' },
        // { key: 6, name: '结算单变更' },
        // { key: 7, name: '结算单开票' }
      ]
    }
  },
  created () {
    this.initTags()
    this.setShowLeftBlock(true)
    //
  },
  methods: {
    showContract () {
      this.handleChangeTag(1)
    },
    initTags () {
      if (
        this.isAuth('Contract_Manage_View') ||
        this.isAuth('Contract_Sale_View')
      ) {
        this.tagList.push({ key: 1, name: '合同' })
      }
      if (
        this.isAuth('ContractChange_Manage_View') ||
        this.isAuth('ContractChange_Sale_View')
      ) {
        this.tagList.push({ key: 2, name: '合同变更' })
      }
      if (
        this.isAuth('Invoice_Manage_View') ||
        this.isAuth('Invoice_Sale_View')
      ) {
        this.tagList.push({ key: 3, name: '合同开票' })
      }
      if (
        this.isAuth('Agreement_Manage_View') ||
        this.isAuth('Agreement_Sale_View')
      ) {
        this.tagList.push({ key: 4, name: '确认单' })
      }

      if (
        this.isAuth('Agreement_Manage_View') ||
        this.isAuth('Agreement_Sale_View')
      ) {
        this.tagList.push({ key: 8, name: '确认单变更' })
      }

      if (
        this.isAuth('Statement_Manage_View') ||
        this.isAuth('Statement_Sale_View')
      ) {
        this.tagList.push({ key: 5, name: '结算单' })
      }
      if (
        this.isAuth('StatementChange_Manage_View') ||
        this.isAuth('StatementChange_Sale_View')
      ) {
        this.tagList.push({ key: 6, name: '结算单变更' })
      }
      if (
        this.isAuth('StatementInvoice_Manage_View') ||
        this.isAuth('StatementInvoice_Sale_View')
      ) {
        this.tagList.push({ key: 7, name: '结算单开票' })
      }

      this.handleChangeTag(this.tagList[0].key)
    },

    handleChangeTag (tag) {
      this.currentTag = tag
      this.allowEdit = false
      this.tagName = this.tagList.find((x) => x.key === tag).name
      let pageType = ''

      this.$store.commit('set_contract_contractSetting', false)
      this.$store.commit('set_contract_statementSetting', false)
      switch (tag) {
        case 1:
          pageType = 'contract'
          this.allowEdit = this.isAuth('Contract_Edit')
          this.createComponent = 'CreateContract'
          break
        case 2:
          pageType = 'contractChange'
          this.allowEdit = this.isAuth('ContractChange_Edit')
          this.createComponent = 'CreateContractChange'
          break
        case 3:
          pageType = 'invoice'
          this.allowEdit = this.isAuth('Invoice_Edit')
          this.createComponent = 'CreateInvoice'
          break
        case 4:
          pageType = 'agreement'
          this.allowEdit = this.isAuth('Agreement_Edit')
          this.createComponent = 'CreateAgreement'
          break
        case 5:
          pageType = 'statement'
          this.allowEdit = this.isAuth('Statement_Edit')
          this.createComponent = 'CreateStatement'
          break
        case 6:
          pageType = 'statementChange'
          this.allowEdit = this.isAuth('StatementChange_Edit')
          this.createComponent = 'CreateStatementChange'
          break
        case 7:
          pageType = 'statementInvoice'
          this.allowEdit = this.isAuth('StatementInvoice_Edit')
          this.createComponent = 'CreateStatementInvoice'
          break
        case 8:
          pageType = 'agreementChange'
          this.allowEdit = this.isAuth('Agreement_Edit')
          this.createComponent = 'CreateAgreementChange'
          break
        default:
          break
      }

      this.$emit('on-change', pageType, this.tagName)
    },
    handleCreate (baseId, versionId) {
      this.$emit('on-create', baseId, versionId)
    }
  },
  computed: {
    contractId () {
      return this.$store.state.contract
        ? this.$store.state.contract.contractId
        : 0
    },
    contractSetting () {
      return this.$store.state.contract
        ? this.$store.state.contract.contractSetting
        : false
    },
    statementSetting () {
      return this.$store.state.contract
        ? this.$store.state.contract.statementSetting
        : false
    }
  }
}
</script>
